@charset "utf-8";
/* ===================================================================
CSS information
 file name  : homepage.css
 author     : Ability Consultant
 style info : トップページ
=================================================================== */
@import "settings";

$f_ale: 'Alegreya', serif;
$f_jul: 'Julius Sans One', sans-serif;
$f_noto: 'Noto Serif JP', sans-serif;

@mixin fsz-cha($pc_fsz:30,$tab_fsz:30,$sp_fsz:17) {
	@include fsz($pc_fsz);
	@include mq(tab) {
		@include fsz($tab_fsz);
	}
	@include mq(sp) {
		@include fsz($sp_fsz);
	}
}

/* -----------------------------------------------------------
	import font
----------------------------------------------------------- */
h2 {
	font-family: $f_ale;
	text-transform: uppercase;
	color: #fff;
	@include fsz(20, 18);
	font-weight: 400;
	letter-spacing: 0.2em;
	position: absolute;
	z-index: 1;
	left: 0;
	@include cV(top, 45%, 2%);
	border: 1px solid #fff;
	border-left: 0px;
	display: block;
	@include cV(padding, 10px 0px, 10px 0px, 0.2em 0px);
	@include cV(padding-left, 130px, 50px, 10%);
	@include cV(padding-right, 10px, 10px, 1em);
}
.slider-nav, .slider-nav5 {
	display: flex;
	.img {
		position: relative;
		outline: none;
		@include transition4s();
		cursor: pointer;
		img {
			border: 2px solid transparent;
			@include transition4s();
		}
		&.slick-current, &:hover {
			img {
				border: 2px solid $c_blue;
			}
		}
	}
	@include mq(sp) {
		display: none !important;
	}
}
.txt_big20 {
	@include fsz-cha(20, 18, 18);
}
.txt_big24 {
	@include fsz-cha(24, 20, 18);
	@include cV(line-height, 2.2, 2.2, 1.5);
}
.txt_big18 {
	@include fsz-cha(18, 16, 16);
}
.txt_big30 {
	@include fsz-cha(30, 28, 25);
	@include cV(letter-spacing, 0.3em, 0);
}
.btn {
	display: inline-block;
	position: relative;
	a {
		text-decoration: none;
	}
	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 1px;
		background: $baseColor;
		left: 0;
		bottom: -4px;
	}
}

/* -----------------------------------------------------------
	open_calendar
----------------------------------------------------------- */
#open_calendar {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 2;
	@include fsz(13);
	@include cV(letter-spacing, 0.1em, 0);
	@include mq(sp) {
		display: none;
	}
	a {
		@include cV(padding, 19px 10px, 1em 1em);
		color: #fff;
		text-align: center;
		background: $c_blue;
		display: block;
		text-decoration: none;
		@include transition4s();
		i {
			display: block;
			margin-bottom: 5px;
		}
		span {
			font-family: $f_noto;
			font-weight: 100;
		}
		&:hover {
			background: rgba(39, 74, 120, 0.9);
		}
	}
}

/* -----------------------------------------------------------
	con_news_bot
----------------------------------------------------------- */
#con_news_bot {
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 2;
	background: url(../images/home/bg_fixed.png) no-repeat;
	background-size: cover;
	color: #fff;
	width: 340px;
	max-width: 100%;
	text-decoration: none !important;
	.box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include cV(padding, 10px, 10px);
		.box_info {
			font-family: $f_noto;
			font-weight: 100;
			@include cV(letter-spacing, 0.2em, 0);
			text-align: center;
			padding-left: 3%;
			.txt_sm {
				text-decoration: none;
				&.decoration {
					text-decoration: underline;
				}
			}
			.txt_big {
				@include fsz(22);
				padding-bottom: 5px;
				text-decoration: underline;
			}
		}
		.img {
			width: 100px;
		}
	}
}
/* -----------------------------------------------------------
	fixsectionmenu
----------------------------------------------------------- */
#fixsectionmenu {
	position: fixed;
	z-index: 3;
	@include cV(right, 30px, 30px, 2em);
	font-family: $f_ale;
	top: 50%;
	@include translateY();
	#sectionmenu {
		@include fsz-cha(14, 14);
		li {
			position: relative;
			@include cV(margin-bottom, 14px, 14px);
			a {
				color: #fff;
				opacity: 0.5;
				text-decoration: none;
				@include transition4s();
				position: relative;
				&::before {
					content: "";
					position: absolute;
					z-index: 2;
					top: 50%;
					margin-top: 0px;
					@include cV(left, -30px, -1.2em);
					@include cV(width, 20px, 1em);
					@include cV(height, 2px, 2px);
					background: #fff;
					opacity: 0.5;

				}
			}
			&.active {
				&::before {
					opacity: 1;
					margin-top: 1px;
				}
				a {
					opacity: 1;
					@include fsz(18);
				}
			}
			&.brown {
				a {
					color: #c8c2be;
					opacity: 1;
					&::before {
						background: #c8c2be;
					}
				}
				&.active {
					a {
						color: $c_blue;
						&::before {
							background: $c_blue;
						}
					}
				}
			}
		}
	}
}
.section {
	min-height: 650px !important;
	height: 100vh;
	position: relative;
	@include mq(sp) {
		min-height: auto !important;
		height: auto;
	}
	&.con_main {
		@include mq(sp) {
			min-height: auto !important;
			height: auto;
		}
	}
	.box_room {
		max-height: 90vh;
	}
}
#content {
	margin: 0 auto;
	width: 1920px;
	max-width: 100%;
	font-family: $f_noto;
	font-weight: 400;
}
/* -----------------------------------------------------------
	con_main
----------------------------------------------------------- */
.con_main {
	position: relative;
	@include mq(sp) {
		background: url(../images/home/bg_session1.jpg) no-repeat;
		padding: 0px 1em 3em;
	}
	#main_movie_you {
	    position: absolute;
	    z-index: 1;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    overflow: hidden;
	    iframe {
	    	height: 100%;
	    	width: 100%;
	    }
	}
	.box_main {
		position: relative;
		z-index: 2;
		height: 100%;
		.txt_top {
			position: absolute;
			@include cV(top,30px, 1em);
			@include cV(right, 120px, auto);
			color: #fff;
			display: flex;
			.logo {
				font-family: $f_noto;
				@include fsz(12);
				font-weight: 100;
				@include cV(letter-spacing, 0.2em, 0);
				@include cV(margin-right, 25px, 1em);
			}
			.box_lang {
				@include fsz(14);
				font-family: $f_jul;
				position: relative;
				font-weight: 400;
				&::before {
					font-family: FontAwesome;
					content: "\f107";
					position: absolute;
					@include cV(right, -20px, 1em);

				}
				li {
					position: relative;
					@include cV(margin-bottom, 15px, 15px, 1em);
					@include transition4s()
					&::after {
						content: "";
						position: absolute;
						z-index: 2;
						left: 0;
						right: 0;
						bottom: -4px;
						width: 110%;
						height: 1px;
						background: #fff;
					}
					a {
						color: #fff;
						text-decoration: none;

					}
					&.noactive {
						opacity: 0;
					}
					&:hover {
						a {
							&:hover {
								opacity: 0.7;
							}
						}
					}
				}
				&:hover {
					.noactive {
						opacity: 1;
					}
				}
			}
		}
		.img_logo {
			@include cV(position, absolute, relative);
			left: 0;
			right: 0;
			@include translateY();
			@include cV(top, 50%, 35%, 0%);
			@include mq(sp) {
				transform: translateY(0);
			}
			.img {
				margin: 0 auto;
				display: block;
				text-align: center;
			}
			.btn_open_clip {
				text-align: center;
				display: block;
				color: #fff;
				font-family: $f_ale;
				font-weight: 400;
				@include cV(letter-spacing, 0.2em, 0);
				@include cV(margin-top, 33px, 20px, -3em);
				text-decoration: none;
				img {
					text-align: center;
					margin: 0 auto;
					display: block;
					@include fsz(50);
					@include cV(margin-bottom, 5px, 5px, 0.2em);
				}
				.en {

					@include fsz(13);
				}
			}
		}
	}
	.box_info {
		@include cV(position, absolute, relative);
		z-index: 2;
		@include cV(bottom, 100px, 0);
		color: #fff;
		width: 1720px;
		max-width: 95%;
		@include cV(left, 0, auto);
		right: 0;
		margin: 0 auto;
		font-family: $f_noto;
		font-weight: 100;
		@include cV(letter-spacing, 0.3em, 0);
		.txt {
			@include cV(line-height, 2.3, 1.8, 1.5);
		}
		.en {
			@include cV(margin-top, 20px, 20px, 1em);
			@include fsz-cha(48, 35, 24);
			@include cV(letter-spacing, 0.3em, 0);
		}
		@include mq(sp) {
			margin-top: 2em;
		}
	}
}

/* -----------------------------------------------------------
	con_concept
----------------------------------------------------------- */
.con_concept {
	background: url('../images/home/img_seasion2-pc.jpg') center center no-repeat;
	@include mq(sp) {
		background: transparent;
		margin: 6% 0px;
		padding-left: 1em;
		padding-right: 1em;
	}
	.box_concept {
		margin: 0 auto;
		width: 1720px;
		max-width: 100%;
		display: flex;
		justify-content: center;
		position: relative;
		@include mq(sp) {
			background: url('../images/home/img_seasion2-sp.jpg') center center no-repeat;
			padding: 20% 5%;
		}
		.box_info {
			-webkit-writing-mode: vertical-rl;
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
			@include cV(line-height, 4, 3, 2);
			@include cV(letter-spacing, 0.3em, 0.2em);
			@include mq(sp) {
				-webkit-writing-mode: inherit;
				-ms-writing-mode: inherit;
				writing-mode: inherit;
			}
			.txt_big {
				@include cV(margin-left, 30px, 30px, 0px);
				@include fsz-cha(20, 20, 18);
				@include cV(margin-bottom, 0px, 1em);
			}
		}
	}
}

/* -----------------------------------------------------------
	con_roomssa
----------------------------------------------------------- */
.con_roomssa {
	.box_roomssa {
		.box_title {
			@include cV(right, 10%, 0, 0);
		}
		.box_info {
			@include mq(min_w, 768px) {
				padding-right: 20px;
			}
			@include cV(right, 0%, 0%, 0%);
			z-index: 2;
			@include cV(width, wCon(800px, 1920px), 100%, 100%);
			.box_info_sub {
				margin-right: auto;
				@include mq(pc) {
					margin-left: wCon(47px, 800px);
				}
				@include mq(sp) {
					padding-left: 0px;
				}
			}
		}
		.box_slick {
			.slider-nav {
				@include cV(margin-left, wCon(442px, 1920px), wCon(100px, 1920px), 0);
				@include mq(min_max_w, 1025px, 1700px) {

				}
			}
		}
	}
}

/* -----------------------------------------------------------
	con_roomsto
----------------------------------------------------------- */
.con_roomsto {
	.box_roomsto {
		h2 {
			color: $baseColor;
			border-color: $baseColor;
		}
		.box_title {
			@include cV(left, 5%, 5%, 0);
			right: auto;
		}
		.box_info {
			right: auto;
			left: 0;
			.box_info_sub {
				margin-left: auto;
				padding-left: 2em;
				@include mq(tab) {
					margin-left: 0px;
				}
				@include mq(sp) {
					padding-left: 0px;
				}
			}
		}
		.box_slick {
			float: right;
			@include cV(float, right, none, none);
			.slider-nav {
				float: right;
				@include cV(margin-right, wCon(442px, 1920px), wCon(100px, 1920px), wCon(442px, 1920px));
			}
		}
	}
}

/* -----------------------------------------------------------
	con_cuisine
----------------------------------------------------------- */
.con_cuisine {
	background: url(../images/home/bg_session5.png) center right no-repeat;
	width: 100%;
	position: relative;
	h2 {
		@include mq(min_max_w, 1025px, 1550px) {
			top: 55%;
		}
		@include mq(tab) {
			top: 57%;
		}
	}
	@include mq(sp) {
		background-position: center bottom;
		padding-bottom: 2em;
		margin-bottom: 2em;
	}
	.box_cuisine {
		width: 1720px;
		max-width: 100%;
		margin-right: auto;
		display: flex;
		justify-content: space-between;
		@include mq(sp) {
			flex-wrap: wrap;
		}
		h2 {
			@include cV(padding-left, 198px, 80px, 1em);
		}
		.box_left {
			@include cV(width, wCon(960px, 1720px), wCon(960px, 1720px), 100%);
			position: relative;
			@include mq(sp) {
				margin-bottom: 2em;
			}
			.box_info {
				@include cV(position, absolute, relative);
				z-index: 2;
				@include cV(top, 68px, 30px, 1em);
				@include cV(left, wCon(98px, 960px), wCon(30px, 960px), 0);
				color: #fff;
				@include cV(letter-spacing, 0.2em, 0);
				@include mq(sp) {
					padding-left: 1em;
					padding-right: 1em;
					margin-bottom: 1em;
					color: $baseColor;
				}
				.txt_big20 {
					@include cV(margin, 17px 0px, 12px 0px, 1em 0px);
				}
				.txt {
					@include cV(line-height, 2.5, 1.8, 1.5);
				}
				.btn {
					@include cV(width, auto, 300px);
					@include cV(margin-top, 20px, 20px, 20px);
					@include mq(max_w, 1024px) {
						margin: 20px auto;
						display: block;
						max-width: 100%;
						&::after {
							display: none;
						}
					}
					a {
						width: 100%;
						display: block;
						@include cV(text-align, left, center);
						color: #fff;
						padding: 0;
						background: transparent;
						border-color: transparent;
						@include mq(sp) {
							background: transparent;
							border: 1px solid $baseColor;
							color: $baseColor;
							padding: 1em .2em;
						}
						&:hover {
							color: #fff !important;
						}
						span {
							text-decoration: underline;
							@include mq(sp) {
								text-decoration: none;
							}
							&::after {
								border-top-color: #fff !important;
								border-right-color: #fff !important;
								@include mq(sp) {
									border-top-color: $baseColor !important;
									border-right-color: $baseColor !important;
								}
							}
						}
					}
				}
			}
		}
		.box_right {
			@include cV(width, wCon(700px, 1720px), wCon(700px, 1720px), 100%);
			.box_slick {
				.img {
					img {
						width: 100%;
					}
				}
				.slider-nav5 {
					width: 490px;
					max-width: 95%;
					@include cV(margin-left, wCon(23px, 700px), wCon(23px, 700px), 0);
					@include cV(margin-top, -35px, -35px, -1em);
					.img {
						@include cV(width, wCon(164px, 490px), wCon(160px, 490px), 100%);
					}
				}
			}
			.box_info {
				@include cV(margin-left, wCon(23px, 700px), wCon(23px, 700px), 0px);
				@include cV(padding-top, 45px, 15px, 1em);
				@include mq(sp) {
					padding-left: 1em;
					padding-right: 1em;
				}
				.txt {
					@include cV(line-height, 2.5, 1.8, 1.5);
				}
				.txt_big20 {
					@include cV(margin, 17px 0px 25px, 12px 0px, 0.7em 0px);
				}
			}
		}
	}
}

/* -----------------------------------------------------------
	con_relaxation
----------------------------------------------------------- */
.con_relaxation {
	position: relative;
	background: linear-gradient(90deg, #fff 27%, #f3f8f1 27%);
	@include mq(sp) {
		padding-left: 1em;
		padding-right: 1em;
		padding-top: 3em;
		padding-bottom: 3em;
	}
	h2 {
		@include mq(tab) {
			top: 10%;
		}
		@include mq(sp) {
			top: 10%;
		}
	}
	.box_relaxation {
		margin: 0 auto;
		width: 1720px;
		max-width: 100%;
		position: relative;
		.box_list {
			display: flex;
			flex-wrap: wrap;
			@include cV(margin, 0px -3px, 0px -3px);
			.img {
				@include cV(width, 25%, 25%, 100%);
				@include cV(padding, 0px 3px, 0px 3px);
				img {
					width: 100%;
				}
				@include mq(tab) {
					margin-bottom: 0.5em;
				}
				&:first-child {
					@include mq(sp) {
						display: block;
					}
				}
				@include mq(sp) {
					display: none;
				}
				img {
					@include mq(sp) {
						width: 100%;
					}
				}
			}
		}
		.box_info {
			position: absolute;
			z-index: 2;
			background: #fff;
			@include cV(top, 10%, 30px, auto);
			@include cV(right, 3%, 30px, 0px);
			@include cV(left, auto, 0px);
			@include cV(width, wCon(550px, 1920px), 50%, 90%);
			@include cV(letter-spacing, 0.2em, 0);
			@include cV(padding, 37px 0px, 1em 0px, 1em 0px);
			-webkit-box-shadow: 0px 0px 14px 1px rgba(0,0,0,0.75);
			-moz-box-shadow: 0px 0px 14px 1px rgba(0,0,0,0.75);
			box-shadow: 0px 0px 14px 1px rgba(0,0,0,0.75);
			@include mq(sp) {
				margin: 0 auto;
				bottom: 5%;
			}
			.txt_big20 {
				@include cV(line-height, 2, 2, 1.5);
				@include cV(padding, 0px wCon(50px,550px), 0px 1em, 0px 1em);
			}
			.txt {
				@include cV(margin-top, 28px, 1em, 1em);
				@include cV(padding, 0px wCon(50px,550px), 0px 1em, 0px 1em);
				@include cV(line-height, 1.8, 1.8, 1.5);
			}
		}
	}
}

/* -----------------------------------------------------------
	con_factory
----------------------------------------------------------- */
.con_factory {
	background: url(../images/home/bg_session7.png) center bottom no-repeat;
	.box_factory {
		width: 1778px;
		max-width: 100%;
		margin-right: auto;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		flex-wrap: wrap;
		@include mq(max_w, 1024px) {
			flex-wrap: wrap;
			padding-bottom: 3em;
		}
		.box_left {
			@include cV(width, wCon(1050px, 1778px), 100%, 100%);
			position: relative;
			.box_slick {
				.img {
					img {
						width: 100%;
					}
				}
				.slider-nav5 {
					width: 490px;
					max-width: 95%;
					float: right;
					@include cV(margin-right, wCon(23px, 700px), wCon(23px, 700px), wCon(423px, 700px));
					@include cV(margin-top, -35px, -35px, -1em);
					.img {
						@include cV(width, wCon(164px, 490px), wCon(160px, 490px), wCon(160px, 490px));
					}
				}
			}
		}
		.box_right {
			@include cV(width, wCon(630px, 1778px), 100%, 100%);
			@include mq(min_max_w, 1025px, 1250px) {
				width: 38%;
			}
			@include mq(tab) {
				margin-top: 2em;
				padding: 0px 2em;
			}
			@include mq(sp) {
				margin-top: 2em;
				padding: 0px 1em;
			}
			.box_list {
				@include cV(width, wCon(528px, 630px), 100%, 100%);
				.box_marneg {
					@include cV(margin, 0px -12px, 0px 0px, 0px 0px);
					width: 100%;
					display: flex;
					flex-wrap: wrap;
				}
				.img {
					@include cV(width, (100%/3), (100%/3), (100%/3));
					@include cV(padding, 0px 12px, 0px 5px);
					@include cV(margin-bottom, 13px, 13px, 1em);
					position: relative;
					img {
						width: 100%;
					}
					span {
						position: absolute;
						@include cV(right, 12px, 5px);
						bottom: 0;
						@include fsz(14);
						background: #fff;
						bottom: -1px;
						padding: 1px 7px;
					}
				}
			}
			.box_info {
				@include cV(letter-spacing, 0.2em, 0);
				@include cV(margin-top, 40px, 30px, 1em);
				@include mq(min_max_w, 1025px, 1250px) {
					margin-top: 1em;
				}
				.txt {
					@include cV(line-height, 2, 2, 1.5);
					@include cV(margin-top, 27px, 27px, 1em);
					@include mq(min_max_w, 1025px, 1250px) {
						margin-top: 1em;
					}
				}
				.box_fac_item {
					display: flex;
					@include cV(margin-top, 52px, 40px, 2em);
					@include mq(min_max_w, 1025px, 1250px) {
						margin-top: 1em;
					}
					@include mq(sp) {
						flex-wrap: wrap;
					}
					.en {
						@include fsz-cha(18, 18);
						line-height: 1.4;
					}
					.jp {
						@include fsz-cha(14, 14);
						@include cV(padding-left, 47px, 40px, 0em);
					}
				}
			}
		}
	}
}

/* -----------------------------------------------------------
	con_access
----------------------------------------------------------- */
.con_access {
	background: linear-gradient(90deg, #f3f8f1 73%, #fff 27%);
	@include cV(letter-spacing, 0.2em, 0);
	@include mq(sp) {
		padding: 5em 0px;
		background: linear-gradient(90deg, #f3f8f1 50%, #fff 50%);
	}
	h2 {
		color: $baseColor;
		border-color: $baseColor;
		top: 50%;
		@include mq(tab) {
			top: 6%;
		}
		@include mq(sp) {
			top: 4%;
		}
	}
	.box_access {
		margin: 0 auto;
		width: 1670px;
		max-width: 100%;
		display: flex;
		position: relative;
		padding: 0px 1em;
		@include mq(tab) {
			padding: 0px 2em;
		}
		@include mq(sp) {
			flex-wrap: wrap;
		}
		&::before {
			content: "";
			position: absolute;
			z-index: 0;
			width: 320px;
			height: 255px;
			@include cV(left, wCon(-132px, 1920px), wCon(-132px, 1920px), wCon(-132px, 1920px));
			background: url(../images/home/bg_session8.png) no-repeat;
			@include mq(sp) {
				background-position: left center;
			}
		}
		.box_left {
			@include cV(width, wCon(560px, 1655px), 75%, 100%);
			display: flex;
			flex-wrap: wrap;
			 > div {
			 	width: 100%;
				@include mq(tab) {
					margin-bottom: 2em;
				}
			 }
			.box_info_top {
				@include mq(min_max_w, 1025px, 1400px) {
					margin-bottom: 8em;
				}
				@include mq(sp) {
					margin-bottom: 2em;
				}
				.txt {
					@include cV(line-height, 2, 2, 1.5);
					@include cV(margin-top, 17px, 1em, 1em);
				}
				.link_map {
					@include cV(margin-top, 17px, 1em, 1em);
					a {
						text-decoration: none;
					}
				}
				.btn_basic {
					margin: 2em auto;
					display: block;
					&::after {
						display: none;
					}
					a {
						background: transparent;
						color: $baseColor;
						padding: 1em .2em;
					}
				}
			}
			.box_info_bot {
				align-self: flex-end;
				@include cV(margin-bottom, 60px, 60px, 1em);
				.txt_phone {
					@include cV(margin-top, 29px, 1em, 1em);
					.number {
						font-family: $f_ale;
						@include fsz-cha(24, 24, 20);
						@include cV(margin-left, 17px, 17px, 1em);
					}
				}
				.btn {
					width: auto;
					@include cV(margin-top, 20px, 1em, 20px);
					@include fsz(15);
					a {
						width: 100%;
						display: block;
						text-align: left;
						color: $baseColor;
						padding: 0;
						background: transparent;
						border-color: transparent;
						&:hover {
							color: $baseColor !important;
						}
						span {

							&::after {
								border-top-color: $baseColor !important;
								border-right-color: $baseColor !important;
							}
						}
					}
				}
			}
		}
		.box_right {
			@include cV(width, wCon(990px, 1655px), wCon(990px, 1655px), 100%);
			.box_map {
				position: relative;
				height: 0;
				overflow: hidden;
				padding-bottom: 59%;
				iframe,
				object,
				embed {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
			.btn {
				@include cV(margin-top, 28px, 28px, 1em);
				@include cV(margin-left, 82px, 82px, 0em);
				@include mq(sp) {
					display: inline;
				}
				&::before {
					content: "";
					position: absolute;
					z-index: 2;
					@include cV(left, -82px, -82px, -2em);
					@include cV(width, 70px, 70px, 2em);
					height: 1px;
					background: $baseColor;
					top: 50%;
					@include mq(sp) {
						display: none;
					}
				}
				&::after {
					display: none;
				}
				i {
					margin-right: 3px;
				}
				a {
					text-decoration: none;
					@include mq(sp) {
						margin: 0 auto;
						display: block;
						text-align: center;
						width: 300px;
						max-width: 100%;
						border: 1px solid $baseColor;
						padding: 1em .2em;
					}
				}
			}
		}
	}
}

/* -----------------------------------------------------------
	con_information
----------------------------------------------------------- */
.con_information {
	background: url(../images/home/bg_seasion9-pc.jpg) repeat;
	@include cV(letter-spacing, 0.2em, 2px);
	@include mq(tab) {
		padding: 2em 0px;
		h2 {
			top: 10%;
		}
	}
	@include mq(sp) {
		padding-top: 2em;
		padding-bottom: 7em;
		background: url(../images/home/bg_seasion9-sp.jpg) center top no-repeat;
		padding-left: 1em;
		padding-right: 1em;
	}
	h2 {
		@include mq(min_max_w, 1025px, 1170px) {
			top: 10%;
		}
		@include mq(sp) {
			top: 17%;
		}
	}
	.box_information {
		margin: 0 auto;
		width: 1450px;
		max-width: 100%;
		padding-bottom: 0px;
		@include mq(min_max_w, 1025px, 1130px) {
			padding-bottom: 130px;
		}
		.box_logo {
			text-align: center;
			color: #fff;
			.img {
				@include mq(sp) {
					margin-bottom: 5em;
				}
			}
			.txt {
				@include cV(margin-top, 20px, 20px, 6em);
			}
			.txt_big30 {
				@include cV(margin-top, 6px, 6px, 1em);
			}
			.box_list_btn {
				display: flex;
				justify-content: center;
				@include mq(sp) {
					flex-wrap: wrap;
					margin-top: 1em;
				}
				.btn_basic {
					@include cV(margin, 17px 7px, 0.5em 7px);
				}
			}
			.box_info {
				display: flex;
				justify-content: space-between;
				font-family: $f_min;
				width: 1460px;
				max-width: 100%;
				padding: 0px 1em;
				@include mq(tab) {
					padding-bottom: 160px;
				}
				@include mq(sp) {
					flex-wrap: wrap;
					padding: 0px;
				}
				@include cV(margin-top, 36px, 36px, 1em);
				.box_table {
					@include cV(width, wCon(775px, 1450px), wCon(775px, 1450px), 100%);
					table {
						margin-left: auto;
						@include cV(width, wCon(630px, 775px), wCon(630px, 775px), 100%);
						tr {
							text-align: left;
							vertical-align: top;
							@include mq(sp) {
								display: block;
								border-bottom: 1px solid #fff;
								padding-bottom: 0.5em;
							}
							th {
								@include cV(width, wCon(133px, 630px), wCon(133px, 630px), 100%);
								@include cV(padding, 6px 3px, 6px 3px, 6px 3px);
								@include mq(sp) {
									display: block;
									font-weight: bold;
								}
							}
							td {
								@include mq(sp) {
									display: block;
									width: 100%;
								}
							}
						}
					}
				}
				.box_contact {
					display: flex;
					justify-content: space-between;
					align-items: flex-end;
					@include mq(tab) {
						flex-wrap: wrap;
						margin-top: -20px;
					}
					@include mq(sp) {
						display: none;
					}
					@include cV(width, wCon(583px, 1450px), 50%, wCon(583px, 1450px));
					.box_txt {
						text-align: left;
						@include cV(width, wCon(300px, 583px), wCon(300px, 583px), wCon(300px, 583px));
						.txt_big18 {
							@include mq(tab) {
								margin: 10px 0px;
							}
						}
						@include mq(tab) {
							margin: 0 auto;
							margin-bottom: 1em;
						}
						.txt_big30 {
							font-family: $f_ale;
							letter-spacing: 0;
							margin-top: 0px;
							@include cV(margin-bottom, 14px, 1em);
						}
					}
					.img {
						@include cV(width, wCon(260px, 583px), wCon(660px, 660px), wCon(660px, 660px));
					}
				}
			}
		}
	}
	.view_sp {
		margin-top: 2em;
		font-family: $f_jul;
		color: #fff;
		text-align: center;
		@include fsz(12);
	}
}

/* -----------------------------------------------------------
	con_bottom
----------------------------------------------------------- */
#con_bottom {
	font-family: $f_jul;
	position: fixed;
	display: block;
	width: 100%;
	z-index: 9;
	text-align: center;
	color: #f2f2f2;
	@include cV(bottom, 41px, 41px, 1em);
	@include fsz(12);
	@include cV(letter-spacing, 0.2em, 0);
	p {
		span {
			position: relative;
			display: inline-block;
			color: $baseColor;
			&::after {
				content: "";
				position: absolute;
				top: 50%;
				@include translateY();
				width: 24px;
				height: 24px;
				@include cV(right, -40px, -40px);
				background: url(../images/home/ic_insta_black.png) no-repeat;
			}
		}
	}
	&.white {
		p {
			span {
				color: #fff;
				&::after {
					background: url(../images/home/ic_insta_white.png) no-repeat;
				}
			}
		}
	}
}

/* -----------------------------------------------------------
	animation
----------------------------------------------------------- */
// .modaal-wrapper {

// }
// .modaal-container {
// 	background: transparent;
// 	box-shadow: none;
// 	width: 100%;
// 	max-width: 100%;
// 	margin: auto;
// }

#popup_2 {
	display: none;
	position: fixed;
	z-index: 9999999999999999;
	top: 0;
	left: 0;
	right: 0;
	top: 50%;
	@include translateY();
	background: #fff;
	z-index: 2;
	text-align: center;
	margin: 0 auto;
	width: 1600px;
	max-width: 100%;
	&.open {
		display: block;
	}
	.box_modal_yt {
		position: relative;
		height: 0;
		padding-bottom: 50%;
		.btn_close {
			position: absolute;
			@include cV(right, 0px, 0px, 0px);
			@include cV(top, -40px, 0px, 0px);
		}
		iframe,
		object,
		embed {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

/* -----------------------------------------------------------
	con_modal_calendar
----------------------------------------------------------- */
.overpage {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.8);
    padding: 0px 1em;
	display: none;
	&.open {
		display: block;
	}
}
#popup_1 {
	display: none;
	position: fixed;
	z-index: 999999 !important;
	top: 0;
	left: 0;
	right: 0;
	top: 50%;
	@include translateY();
	background: #fff;
	z-index: 2;
	text-align: center;
	margin: 0 auto;
	width: 1280px;
	max-width: 95%;
	@include mq(sp) {
		height: 100%;
	}
	&.open {
		display: block;
	}
	@include cV(padding, 70px 2em, 30px 2em, 2em 0.5em);
	.box_modal_calendar {
		position: relative;
		.btn_close {
			position: absolute;
			@include cV(right, 0px, 0px, 0.5em);
			@include cV(top, -50px, 0px, -1em);
		}
	}
	h3 {
		text-align: center;
		text-transform: uppercase;
		font-family: $f_ale;
		font-weight: 400;
		@include cV(letter-spacing, 0.1em, 0);
	}
	.txt {
		@include cV(margin-bottom, 10px, 1em);
	}
	.img {
		@include cV(margin-bottom, 30px, 1em);
	}
	.box_select {
		display: flex;
		justify-content: center;
		margin: 0 auto;
		@include mq(sp) {
			flex-wrap: wrap;
		}
		.box_day {
			font-family: $f_ale;
			text-align: left;
			@include cV(margin, 0px 17px, 0px 1em);
			@include mq(sp) {
				width: 100%;
				margin-bottom: 2em;
			}
			input[type="text"] {
			    background: url(../images/home/ic_cal.png) right center no-repeat #fff;
			    width: 100%;
			    @include fsz-cha(34, 30, 30);
			    border: none;
			    border-bottom: 2px  solid $baseColor;
			    color: #2b2b2b;
			    margin-top: 4px;
			}
		}
		.box_select_number {
			display: flex;
			.box_sta, .box_per, .box_roo {
				font-family: $f_ale;
				@include cV(margin, 0px 17px, 0px 1em);
				text-align: left;
				@include mq(sp) {
					width: 33%;
				}
				select {
					margin-top: 4px;
				    @include fsz-cha(34, 30, 30);
				    border: none;
				    border-bottom: 2px  solid $baseColor;
				    color: #2b2b2b;
				    width: 100%;
				    height: 49px;
				    outline: none;
				}
			}
		}
	}
	.box_list_btn {
		display: flex;
		justify-content: center;
		@include mq(sp) {
			flex-wrap: wrap;
		}
		@include cV(margin-top, 58px, 2em);
		.btn_basic {
			@include cV(margin, 0px 10px, 1em 0px);
			&.blue {
				a {
					span {
						&:after {
							display: none;
						}
					}
					&:hover {
						border: 1px solid $c_blue;
						color: $c_blue ;
					}
				}
			}
			&.black {
				a {
					&:hover {
						border: 1px solid $baseColor;
						color: $baseColor !important;
						span {
							&:after {
								border-top-color: $baseColor;
								border-right-color: $baseColor;
							}
						}
					}
				}
			}
		}
	}
	.txt_bre {
		@include cV(margin-top, 20px, 1.5em);
	}
	.txt_booking {
		@include cV(margin-top, 45px, 1em);
	}
	.txt_phone {
		.jp {
			font-family: $f_min;
			@include cV(margin-right, 10px, 1em);
		}
		.number {
			font-family: $f_ale;
			letter-spacing: 0.1em;
			@include mq(sp) {
				@include fsz(20);
			}
		}
	}
}

/* -----------------------------------------------------------
	con_btn_bottom
----------------------------------------------------------- */
.con_btn_bottom {
	display: flex;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 99999;
	div {
		width: 33.33333%;
		padding: 12px 0px;
		text-align: center;
		a {
			color: #fff;
			text-decoration: none;
		}
		i {
			display: block;
			margin-bottom: 0.5em;
		}
		&.box_tel_sp {
			background: #c8c2be;
			a {
				color: $baseColor;
				text-decoration: none;
			}
		}
		&.box_cal_sp {
			background: $c_blue;
			color: #fff;
		}
		&.box_nav_sp {
			background: $baseColor;
			color: #fff;
		}
	}
}

/* -----------------------------------------------------------
	con_menu_sp
----------------------------------------------------------- */
.con_menu_sp {
	position: fixed;
	z-index: 99999999;
	width: 100%;
	height: 100%;
	top: 0;
	background: #fff;
	padding: 0px wCon(50px, 750px);
	padding-top: 4em;
	display: none;
	&.open {
		display: block;
	}
	.btn_close {
		position: absolute;
		right: 1em;
		top: 1em;
	}
	.box_menu_sp {
		ul {
			margin-bottom: 1em;
			li {
				text-align: center;
				padding: 0.7em 1em;
				border-bottom: 1px solid #c8c2be;
				a {
					text-decoration: none;
					display: block;
					position: relative;
					&::after {
						font-family: FontAwesome;
						content: "\f105";
						position: absolute;
						right: 0;
					}
				}
				&.active {
					background: $c_blue;
					a {
						color: #fff;
					}
				}
			}
		}
	}
}