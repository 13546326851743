@charset "utf-8";
// @import "compass";
$experimental-support-for-svg: true;

//----------------------------------------------//
// *Media Queries								//
//----------------------------------------------//
$bp_tab: 1024px; //tab
$bp_sp: 767px; //sp

@mixin mq($device,$val:null,$val2:null) {
	@if $device == pc {
	//pc
		@media only screen and (min-width: $bp_tab + 1){@content;}
		@media print {@content;}
		.ie8 & {@content;}
	}@else if $device == tab {
	//tab
		@media only screen and (min-width: $bp_sp + 1) and (max-width: $bp_tab){@content;}
	}@else if $device == tab_l {
	//tab_l
		@media only screen and (min-width: ($bp_tab - $bp_sp) / 2 + $bp_sp + 1) and (max-width: $bp_tab){@content;}
	}@else if $device == tab_s {
	//tab_s
		@media only screen and (min-width: $bp_sp + 1) and (max-width: ($bp_tab - $bp_sp) / 2 + $bp_sp){@content;}
	}@else if $device == sp {
	//sp
		@media only screen and (max-width: $bp_sp){@content;}
	}@else if $device == sp_l {
	//sp_l
		@media only screen and (min-width: ($bp_sp - 320) / 2 + 320+ 1) and (max-width: $bp_sp){@content;}
	}@else if $device == sp_s {
	//sp_s
		@media only screen and (max-width: ($bp_sp - 320) / 2 + 320){@content;}
	}@else if $device == pc_tab {
	//pc & tab
		@media only screen and (min-width: $bp_sp + 1){@content;}
		@media print {@content;}
		.ie8 & {@content;}
	}@else if $device == tab_sp {
	//tab & sp
		@media only screen and (max-width: $bp_tab){@content;}
	}@else if $device == min_w {
	//max-width
		@media only screen and (min-width: $val){@content;}
		@media print {@content;}
		.ie8 & {@content;}
	}@else if $device == max_w {
	//min-width
		@media only screen and (max-width: $val){@content;}
		@if $val > $bp_tab {
			@media print {@content;}
			.ie8 & {@content;}
		}
	}@else if $device == min_max_w {
	//min-width ~ max-width
		@media only screen and (min-width: $val) and (max-width: $val2){@content;}
		@if $val2 > $bp_tab {
			@media print {@content;}
			.ie8 & {@content;}
		}
	}@else if $device == min_h {
	//max-height
		@media only screen and (min-height: $val){@content;}
	}@else if $device == max_h {
	//min-height
		@media only screen and (max-height: $val){@content;}
	}@else if $device == min_max_h {
	//min-height ~ max-height
		@media only screen and (min-height: $val) and (max-height: $val2){@content;}
	}
}


//----------------------------------------------//
// *Variable									//
//----------------------------------------------//

// メインカラム
$columnWidth: 1024;

// フォントのベースサイズ
$baseSize: 15;

// フォントのベーカラー
$baseColor: #111111;
$c_blue: #274a78;

// フォントファミリー
@font-face {
	font-family: YuGothicM;
	src: local("Yu Gothic Medium");
}
$f_min: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;


//----------------------------------------------//
// *Placeholder									//
//----------------------------------------------//

// コンテンツ幅 - baceWidth
%bw {
	max-width: $columnWidth + px;
	margin: auto;
	@include mq(tab_sp) {
		width: 95%;
	}
}

// クリアフィックス - clearFix
%cf {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}


//----------------------------------------------//
// *Function									//
//----------------------------------------------//

// %計算
@function wCon($width,$parentWidth:$columnWidth) {
	@return $width / $parentWidth * 100%;
}


//----------------------------------------------//
// *mixin										//
//----------------------------------------------//

// フォントサイズ
// @include fsz(任意のサイズ,親要素のサイズ); ※px不要
@mixin fsz($size:$baseSize,$parent:null){
	$i: $size * (100 / $baseSize);

	@if $parent == null {
		font-size: ceil($i * 1%);
	}@else {
		font-size: ceil(100% * ($size / $parent));
	}
}

// 値を振り分け - choiceValue
// @include cV(プロパティ,pc幅,tab~sp幅); or @include cV(プロパティ,pc幅,tab幅,sp幅);
@mixin cV($pro, $v1:0, $v2:0, $v3:null) {
	#{$pro} : $v1;
	@if $v3 == null {
		@include mq(sp) {
			#{$pro} : $v2;
		}
	}@else {
		@include mq(tab) {
			#{$pro} : $v2;
		}
		@include mq(sp) {
			#{$pro} : $v3;
		}
	}
}

// ホバー
// @include hover {
//		style
// }
@mixin hover() {
	.mouse &:hover,
	.touch &.touchstart {
		@content;
	}
	.touch & {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		&.touchend {
			// @include transition-delay(200ms);
		}
	}
}

// 幅を決めて中央寄せ - choiceWidth
// @include cW(任意の幅px);
@mixin cW($width) {
	max-width: $width;
	width: 95%;
	margin: auto;
}

// 画像を拡大
// @include zoomImg(拡大率,x軸基点);
@mixin zoomImg($mag:140%,$cri:center) {
	overflow: hidden;
	img {
		position: relative;
		width: $mag;
		max-width: none;
		@if $cri == center {
			margin-left: - ($mag - 100) / 2;
		}
		@if $cri == right {
			margin-left: - ($mag - 100);
		}
	}
}

// リンクの下線を逆にする
// @include reverseDecoration;
@mixin reverseDecoration {
	text-decoration: none !important;
	@include hover {
		text-decoration: underline !important;
	}
}

// 縦方向のグラデーション(IE8対応)
// @include gradient(上側のカラー,下側のカラー);
@mixin gradient($c1:#000, $c2:#fff) {
	@include background(linear-gradient($c1,$c2));
	@include filter-gradient($c1,$c2);
}

@mixin vertical_write() {
	-webkit-writing-mode: vertical-lr;
	-ms-writing-mode: tb-lr;
	writing-mode: vertical-lr;
}

@mixin translateY() {
	-webkit-transform: translateY(-50%);
	   -moz-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	     -o-transform: translateY(-50%);
	        transform: translateY(-50%);
}

@mixin transition4s() {
	-webkit-transition: all .4s ease;
	   -moz-transition: all .4s ease;
	    -ms-transition: all .4s ease;
	     -o-transition: all .4s ease;
	        transition: all .4s ease;
}